#page-topbar {
    background-color: #f5f6f8;
}
.data-dz-thumbnail{
    display: none!important;
}
.page-content-2 {
    display: none;
}

.contact-icons-desktop {
    display: none;
}

/*.page-content {
    padding-top: 70px;
}*/
.btn-mini {
    margin-left: 20px;
}

.dd-none {
    display: none;
}

.mm-none {
    display: block;
}

.logo-new {
    height: 90px;
}
.my-modal {
    width: 90vw ;
    max-width: 90vw;
}

.btn-min-wi {
    min-width: 90px;
}
@media (max-width: 1600px) {
    .logo-new {
        height: 80px;
    }

    .vertical-menu {
        width: 350px;
    }
    .main-content{
        margin-left: 350px;
    }
    #page-topbar {
        left: 350px;
    }
    .footer {
        left: 350px;
    }
}

.three-rem  {
    padding-top: 0rem;
}
.notification-icon-color {
    color:#2c323c!important;
}

.div-none-mob {
    display: none;
}
.div-none-web {
    display: block;
}

.invoice-logo {
    height: 90px;
}

.invoice-div {
    padding: 20px;
}

@media (max-width: 1200px) {
    .invoice-gl {
        font-size: 11px;
    }

    .invoice-div-card-body {
        padding: 0px;
    }

    .invoice-div {
        padding: 10px;
    }
    .invoice-logo {
        height: 40px;
    }
    .div-none-mob {
        display: block;
    }
    .div-none-web {
        display: none;
    }



    .notification-icon-color {
        color:white!important;
    }
    .three-rem  {
        padding-top: 2rem;
    }
    .footer {
        left: 0px;
    }
    .dd-none {
        display: block;
    }

    .mm-none {
        display: none;
    }

    .btn-mini {
        margin: 0px;
    }

    .btn {
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .right-bar {
        width: 100%;
    }

    .contact-icons-desktop {
        display: block;
    }

    .contact-icons-mob {
        display: none;
    }

    .vertical-menu {
        display: none;
    }

    .service-mob {
        display: none;
    }

    .page-content-2 {
        display: block;
    }

    .navv-mobile {
        display: none;
    }

    .langauge-mob {
        display: none;
    }

    .main-content {
        margin-left: 0px;
    }

    #page-topbar {
        left: 0px;
        background-color: #151B21;
        border-bottom: 1px solid #444a56;
    }
}

.errormessage {
    color: red;
}

.copy-btn {
    position: absolute;
    right: 5px;
    margin-left: 20px;
    cursor: pointer;
    display: inline-block;
    width: auto;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    background-color: #f7f6f5;
    color: #3b414d;
    z-index: 1;
    transition: background-color .3s, color .3s, opacity .3s, visibility .3s, transform .3s;
}

.copy-btn:hover {
    background-color: #eaeaea;
}


.address-box {
    border: 1px solid #f1b44c;
    padding: 10px;
    border-radius: 10px;
    background-color: #f7f9fb;
}

.traking-box {
    border: 1px solid #f1b44c;
    padding: 10px;
    border-radius: 10px;
    background-color: #f7f9fb;
}


.traking-box-2 {
    border: 1px solid #f1b44c;
    padding: 5px;
    border-radius: 10px;
    background-color: #f7f9fb;

}

.traking-success {
    border: 2px solid #34c38f;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 12px;
}

::-webkit-scrollbar:horizontal {
    height: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}
